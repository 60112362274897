<template>
  <section class="apps-list">
    <div class="apps-list__info" v-if="!screenSizes.isLargeScreen">
      <div
          class="apps-list__nav"
          :class="{ 'apps-list__nav--open': mobileNavigationOpen }"
      >

        <div class="nav-btn" @click="toggleMobileNavigation()" :class="{ 'nav-btn--open': firstMobileCategoryOpen}">
          <component :is="firstMobileCategory.icon" class="nav-btn__icon"></component>
          <div class="nav-btn__text">
            {{ firstMobileCategory.name }}
          </div>
        </div>
        <template v-show="mobileNavigationOpen">
          <router-link
              class="nav-btn__link router-link__no-style"
              v-for="category in routes"
              :key="category.routeName"
              :to="{ name: 'apps', hash: `#${category.routeName}` }"
              :style="{
                'visibility': mobileNavigationOpen ? 'visible' : 'hidden',
              }"
          >
            <div
                class="nav-btn"
                :class="{ 'nav-btn--open': currentCategory === category.routeName }"
                @click="setCategoryFromMobile(category)"
            >
              <component :is="category.icon" class="nav-btn__icon"></component>
              <div class="nav-btn__text">
                {{ category.name }}
              </div>
            </div>
          </router-link>
        </template>
      </div>
      <div class="apps-list__filter-selector" v-if="!mobileNavigationOpen">
        <t-selector
            height="38px"
            :items="appsFilters"
            v-model="currentFilter"
            @select="emitFilterUpdate"
        ></t-selector>
      </div>
    </div>
    <t-row :gap="cardsGap">
      <t-col
          v-for="app in apps"
          :key="app.id"
          :cols="6"
          :tablet="tabletCols"
          :mobile="12"
      >
        <router-link
            v-show="!loading"
            class="router-link__no-style apps-list__card-link"
            :to="{
              name: 'app',
              params: {
                app: app.slug,
                categoryFrom: $route.hash.replace('#', '') || 'apps'
              },
            }"
        >
          <apps-app-card
              @click.native="appCardClick(app.categorySlug)"
              class="apps-list__card"
              :title="app.title"
              :description="app.shortDescription"
              :icon="app.icon"
              :is-ads="app.isAds"
              :is-trending="app.isTrending"
              :is-new="app.isNew"
              :is-verified="app.isVerified"
          >
          </apps-app-card>
        </router-link>
      </t-col>
    </t-row>
    <apps-app-cards-skeleton v-if="loading"></apps-app-cards-skeleton>
    <apps-app-cards-skeleton v-if="loadingMoreApps"></apps-app-cards-skeleton>
    <apps-search-not-found v-if="!loading && apps.length === 0" class="app-list__not-found"></apps-search-not-found>
  </section>
</template>

<script>
import AppsAppCard from "~/components/apps/AppsAppCard.vue";
import IconAppsAll from '@img/icons/tonscan/apps/all.svg'
import IconDropDown from '@img/icons/tonscan/dropdown.svg'
import {searchApps} from "~/api/typesense";
import {mapState} from "vuex";
import AppsNavigation from "~/components/apps/AppsNavigation.vue";
import {TONSCAN_APPS_ENDPOINT} from '~/config';
import AppsSearchNotFound from "~/components/apps/AppsSearchNotFound.vue";
import AppsAppCardsSkeleton from "~/components/apps/AppsAppCardsSkeleton.vue";
import IconAppsWallets from "@img/icons/tonscan/apps/wallets.svg";
import IconAppsExchanges from "@img/icons/tonscan/apps/exchanges.svg";
import IconAppsMarketplaces from "@img/icons/tonscan/apps/marketplaces.svg";
import IconAppsGames from "@img/icons/tonscan/apps/games.svg";
import IconAppsStaking from "@img/icons/tonscan/apps/staking.svg";
import IconAppsSocial from "@img/icons/tonscan/apps/social.svg";
import IconAppsTools from "@img/icons/tonscan/apps/tools.svg";
import IconAppsOther from "@img/icons/tonscan/apps/other.svg";
import {customRef} from "vue";

export default {
  name: "AppsList",
  components: {
    AppsAppCardsSkeleton,
    AppsSearchNotFound,
    AppsNavigation,
    AppsAppCard,
    IconAppsAll,
    IconDropDown
  },
  inject: ['setIsAppPageLoading', 'setIsAppPage', 'getCurrentCategory', 'setCurrentCategory'],
  props: {
    searchValue: {
      type: String,
      default: ''
    },
    currentFilter: {
      type: String,
      default: 'popular'
    }
  },
  data() {
    return {
      // isNavOpen: false,
      appsPerPage: 20,
      loading: true,
      loadingMoreApps: false,
      page: 1,
      apps: [],
      found: 0,
      isCategoryChanging: false,
      mobileNavigationOpen: false,
      currentMobileCategory:  {
        name: this.$t('apps.categories.all_apps'),
        routeName: 'apps',
        icon: IconAppsAll
      },
      firstMobileCategory: {
        name: this.$t('apps.categories.all_apps'),
        routeName: 'apps',
        icon: IconAppsAll
      },
      appsCategory: {
        name: this.$t('apps.categories.all_apps'),
        routeName: 'apps',
        icon: IconAppsAll
      },
      windowInnerWidth: window.innerWidth,
      mobileNavigationClosing: false
    }
  },
  computed: {
    firstMobileCategoryOpen() {
      // (currentCategory !== 'apps' || mobileNavigationOpen) && (firstMobileCategory.routeName !== 'apps' || mobileNavigationOpen)
      console.log('this.currentCategory', this.currentCategory)
      if (this.mobileNavigationOpen) {
        if (this.currentCategory !== 'apps') {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.currentCategory !== 'apps') {
          return true;
        } else {
          return false;
        }
      }
    },
    routes() {
      return [
        // {
        //   name: this.$t('apps.categories.all'),
        //   routeName: 'apps',
        //   icon: IconAppsAll
        // },
        {
          name: this.$t('apps.categories.wallets'),
          routeName: 'wallets',
          categorySlug: 'wallet',
          icon: IconAppsWallets
        },
        {
          name: this.$t('apps.categories.exchanges'),
          routeName: 'exchanges',
          categorySlug: 'exchange',
          icon: IconAppsExchanges
        },
        {
          name: this.$t('apps.categories.marketplaces'),
          routeName: 'marketplaces',
          categorySlug: 'marketplace',
          icon: IconAppsMarketplaces
        },
        {
          name: this.$t('apps.categories.games'),
          routeName: 'games',
          categorySlug: 'game',
          icon: IconAppsGames
        },
        {
          name: this.$t('apps.categories.staking'),
          routeName: 'staking',
          categorySlug: 'staking',
          icon: IconAppsStaking
        },
        {
          name: this.$t('apps.categories.social'),
          routeName: 'social',
          categorySlug: 'social',
          icon: IconAppsSocial
        },
        {
          name: this.$t('apps.categories.tools'),
          routeName: 'tools',
          categorySlug: 'tool',
          icon: IconAppsTools
        },
        {
          name: this.$t('apps.categories.other'),
          routeName: 'other',
          categorySlug: 'other',
          icon: IconAppsOther
        },
      ]
    },
    appsAmount() {
      return this.apps.length
    },
    ...mapState({
      appsPageFilters: state => state.appsPageFilters,
      appLocale: state => state.appLocale
    }),
    cardsGap() {
      return this.screenSizes.isMediumScreen ? 16 : 20
    },
    tabletCols() {
      return 6;
      // return this.windowInnerWidth < 580 ? 12 : 6;
    },
    currentCategory() {
      return this.getCurrentCategory()
    },
    isCurrentCategoryAll() {
      return this.currentCategory === 'apps' || this.currentCategory === 'all' || !this.currentCategory
    },
    appsFilters() {
      return [
        {
          name: this.$t('apps.filters.top'),
          value: 'popular'
        },
        {
          name: this.$t('apps.filters.newest'),
          value: 'newest'
        }
      ]
    }
  },
  methods: {
    emitFilterUpdate(filter) {
      this.$emit('filterUpdate', filter)
    },
    setCategoryFromMobile(category) {
      this.currentMobileCategory = category
      this.firstMobileCategory = category
      this.setCurrentCategory(category.routeName);
      this.mobileNavigationOpen = false;
    },
    toggleMobileNavigation() {
      if (this.firstMobileCategory.routeName === 'apps' && this.mobileNavigationOpen) {
        this.setCategoryFromMobile(this.appsCategory)
        return
      }
      this.firstMobileCategory = this.appsCategory;
      // this.setCategoryFromMobile(this.appsCategory)
      this.mobileNavigationOpen = !this.mobileNavigationOpen;
    },
    appCardClick(category) {
      // this.setCurrentCategory = category
    },
    getCategorySlugByRouteName(routeName) {
      return this.routes.find((route) => route.routeName === routeName).categorySlug
    },
    async getAppsWithCategoryAndFilter() {

      let paramsObj = {
        per_page: this.appsPerPage,
        query_by: 'name,tags,category_slug',
        page: this.page
      }

      if (this.currentFilter === 'popular') {
        paramsObj.sort_by = 'weight:DESC'
      } else if (this.currentFilter === 'newest') {
        paramsObj.sort_by = 'created_at:DESC'
      }

      // If search value not empty - search in all categories
      if (this.searchValue) {
        paramsObj.q = this.searchValue
        return await this.getApps(paramsObj);
      }

      // If category selected - get apps by category
      if (!this.isCurrentCategoryAll) {
        paramsObj.filter_by = `category_slug:${ this.getCategorySlugByRouteName(this.currentCategory) }`;
        paramsObj.q = '*';
        paramsObj.query_by = 'category_slug';

        if (this.currentCategory === 'exchanges') {
          paramsObj.filter_by = 'category_slug:[exchange, dex]';
        }

        return await this.getApps(paramsObj);
      }

      // If category not selected - get all apps
      paramsObj.q = ''
      return await this.getApps(paramsObj);
    },
    async resetApps(callback) {
      this.page = 1;
      this.loading = true;
      await callback();
      this.loading = false;
    },
    handleScroll() {
      const nearBottom =
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 20;
      if (nearBottom) {
        if (!this.loadingMoreApps) {
          this.loadMoreApps()
        }
      }
    },
    async loadMoreApps() {
      if (this.loadingMoreApps === true) {
        return
      }
      if (this.found <= this.appsPerPage * this.page) {
        return
      }
      this.loadingMoreApps = true;
      this.page++;
      let moreApps = await this.getAppsWithCategoryAndFilter();

      this.apps.push(...moreApps);
      this.loadingMoreApps = false;
    },
    async updateFilter(filter) {
      await this.resetApps(async () => {
        this.apps = await this.getAppsWithCategoryAndFilter()
      })
    },
    toAppName(title) {
      return title.toLowerCase().split(' ').join('_')
    },
    async getApps(params) {
      const res = await searchApps(params);
      this.found = res.found;
      this.setIsAppPageLoading(false);
      this.loading = false;

      return res.hits.map((item) => {
        item = item.document

        const appObj = {
          title: item.name,
          icon: `${TONSCAN_APPS_ENDPOINT}${item.avatar}`,
        }

        if (!item.avatar) {
          appObj.icon = undefined;
        }

        if (item.is_new) {
          appObj.chip = 'new'
        }

        if (item.is_trending) {
          appObj.chip = 'hot'
        }

        appObj.slug = item.slug
        appObj.id = item.id
        appObj.isAds = item.is_ads
        appObj.isTrending = item.is_trending
        appObj.isNew = item.is_new
        appObj.isVerified = item.is_verified

        appObj.shortDescriptionEn = item.short_description
        appObj.shortDescriptionRu = item.short_description_ru

        if (this.appLocale === 'ru') {
          appObj.shortDescription = appObj.shortDescriptionRu;
        } else {
          appObj.shortDescription = appObj.shortDescriptionEn;
        }
        return appObj
      })
    },
    setWindowInnerWidth() {
      this.windowInnerWidth = window.innerWidth
    }
  },
  async beforeMount() {
    this.setIsAppPage(false);
    const currentCategory = this.routes.find((category) => category.routeName === this.currentCategory)
    if (currentCategory) {
      this.setCategoryFromMobile(currentCategory)
    }

    this.loading = true;
    this.apps = await this.getAppsWithCategoryAndFilter();
    this.loading = false;
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.setWindowInnerWidth);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.setWindowInnerWidth);
  },
  watch: {
    async currentFilter(newVal) {
      await this.updateFilter()
    },
    appsPageFilters: {
      async handler(newVal, oldVal) {
        await this.resetApps(async () => {
          this.apps = await this.getAppsWithCategoryAndFilter();
        })
      },
      deep: true
    },
    currentCategory: {
      async handler() {

        this.isCategoryChanging = true
        if (!this.searchValue) {
          this.$emit('clear');
        }
        if (this.currentCategory !== 'apps' && this.searchValue) {
          this.$emit('clear');
        }
        this.$nextTick(async () => {
          await this.resetApps(async () => {
            this.apps = await this.getAppsWithCategoryAndFilter();
          })
          this.isCategoryChanging = false
        });

        console.log('currentCategory', this.currentCategory)
        console.log('this.searchValue', this.searchValue)
        if (this.currentCategory === 'apps') {
          this.firstMobileCategory = this.appsCategory
        } else {
          this.firstMobileCategory = this.routes.find((category) => category.routeName === this.currentCategory)
        }
      },
      deep: true
    },
    async searchValue(newSearchValue) {
      console.log('searchValue', this.currentCategory)
      if (!this.isCategoryChanging) {
        await this.resetApps(async () => {
          this.apps = await this.getAppsWithCategoryAndFilter();
        })
      }

    },
    appLocale(newAppLocale) {
      for (const app of this.apps) {
        if (newAppLocale === 'ru') {
          app.shortDescription = app.shortDescriptionRu;
        } else {
          app.shortDescription = app.shortDescriptionEn;
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>

  .apps-list {
    margin-top: 20px;

    &__info {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      .nav-btn {
        user-select: none;
        box-sizing: border-box;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: var(--apps-navigation-btn-background);
        padding: 10px 15px 10px 12px;
        border-radius: 24px;
        color: var(--app-list-nav-btn-text);
        border: 1px solid var(--apps-navigation-btn-border-color);
        max-height: 44px;
        min-height: 44px;

        &__link {
          display: block;
          max-height: 44px;
        }

        &:active {
          background: var(--apps-navigation-btn-active-background-color);
        }

        &__icon {
          width: 20px;
          height: 20px;
          color: inherit;
        }

        &__icon-dropdown--open {
          transform: rotate(180deg) scaleX(-1);
        }

        &__text {
          font-size: 14px;
          margin-left: 8px;
          line-height: 20px;
          //padding-top: 1px;
          color: inherit;
        }

        &--open {
          border: 2px solid var(--apps-navigation-btn-selected-border-color);
          padding: 10px 14px 10px 11px;
          //background: rgba(0, 101, 245, 0.1);
          background: var(--apps-navigation-btn-selected-background);
          color: var(--apps-navigation-btn-selected-text-color);
        }
      }
    }

    &__nav {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: 44px;
      transition-property: max-height;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;

      &--open {
        max-height: 500px;
      }
    }

    &__amount {
      font-size: 14px;
      line-height: 32px;
      text-transform: uppercase;
    }

    &__card {
      margin-bottom: 20px;
    }

    &__filter-selector {
      display: flex;
      align-items: center;
      position: absolute;
      right: 8px;
    }

    &__not-found {
      height: 50vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }



  @media screen and (max-width: 480px) {

    .t-col:first-child .apps-list__card-link .apps-list__card {
      border-top: 1px solid var(--card-border-color);
    }

    .apps-list {
      margin-top: 0;

      &__info {
        margin: 12px 0 12px 0;
      }

      &__card {
        margin: 0 -16px;
        border-radius: 0;
        border-top: none;
        border-right: none;
        border-left: none;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .t-col {

    }
  }


</style>
