<template>
  <section class="app">
    <div class="app__header">
      <div :style="{ 'background-image': `url(${icon})` }" class="app__logo">
        <t-skeleton-loader
            v-if="loading"
            border-radius="32px"
            type="rectangle"
            width="100%"
            height="100%"
        ></t-skeleton-loader>
      </div>
      <div class="app__heading">
        <h1 class="app__title" v-if="!loading">
          {{ title }}
          <icon-verified v-if="isVerified"></icon-verified>
        </h1>
        <t-skeleton-loader
            v-else
            type="paragraph"
            height="32px"
            width="100%"
            border-radius="32px"
        ></t-skeleton-loader>
        <div class="app__short-description">
          <span v-if="!loading">{{ shortDescription }}</span>
          <t-skeleton-loader
              v-else
              type="paragraph"
              width="100%"
              height="24px"
              border-radius="32px"
          ></t-skeleton-loader>
        </div>
        <div class="app__open-btn" v-if="!isMobile">
          <a target="_blank" :href="linkFull" v-if="!loading">
            <t-btn color="blue">
              {{ $t('apps.app.open_app') }}
            </t-btn>
          </a>
          <t-skeleton-loader
            v-else
            type="button"
            height="40px"
            width="100%"
          ></t-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="app__open-btn--wide" v-if="isMobile && !loading">
      <a target="_blank" :href="linkFull" v-if="!loading">
        <t-btn :to="link" block color="blue">
          {{ $t('apps.app.open_app') }}
        </t-btn>
      </a>
    </div>
    <div class="app__open-btn--wide" v-else-if="loading && isMobile">
      <t-skeleton-loader
          type="button"
          height="40px"
          width="100%"
      ></t-skeleton-loader>
    </div>
    <div class="app__description">
      <span v-if="!loading" v-html="descriptionMd"></span>
      <t-skeleton-loader
          v-else
          type="rectangle"
          width="100%"
          height="115px"
          border-radius="24px"
          :class="{ 'app__description--loading': loading }"
      ></t-skeleton-loader>
    </div>
    <div class="app__screenshots" v-if="loading">
      <div
          class="app__screenshot"
          v-for="index in 4"
          :key="index"
      >
        <t-skeleton-loader
            type="rectangle"
            width="inherit"
            height="100%"
        >
        </t-skeleton-loader>
      </div>
    </div>
    <div class="app__screenshots" v-else-if="screenshots.length !== 0">
        <div
          class="app__screenshot-wrapper"
          v-for="(screenshot, index) in screenshots"
          :key="index"
        >
          <div
              class="app__screenshot"
              :class="{
                'app__screenshot--portrait': screenshotsOrientation === 'portrait',
                'app__screenshot--landscape': screenshotsOrientation === 'landscape',
              }"
              :style="{
                'background-image': `url(${screenshot})`,
              }">
          </div>
        </div>
    </div>
    <div class="app__delimiter"></div>
    <div class="app__info">
      <div class="info__item">
        <div class="item__name">
          {{ $t('apps.app.category') }}
        </div>
        <div class="item__value">
          <router-link
              :to="{
                name: 'apps',
                hash: `#${getCategoryBySlug(categorySlug, true)}`
              }"
              v-if="!loading"
          >
            {{ categoryTitle }}
          </router-link>
          <t-skeleton-loader
              v-else
              type="paragraph"
              width="100%"
              height="24px"
              border-radius="32px"
          ></t-skeleton-loader>
        </div>
      </div>
      <div class="info__item">
        <div class="item__name">
          {{ $t('apps.app.website') }}
        </div>
        <div class="item__value">
          <a :href="linkFull" target="_blank" v-if="!loading">{{ link }}</a>
          <t-skeleton-loader
              v-else
              type="paragraph"
              width="100%"
              height="24px"
              border-radius="32px"
          ></t-skeleton-loader>
        </div>
      </div>
      <div class="info__item" v-if="address">
        <div class="item__name">
          {{ $t('apps.app.blockchain_address') }}
        </div>
        <div class="item__value">
          <div class="value-wrapper" v-if="!loading">
            <ui-copy-button class="card-main-address"
                v-bind:successMessage="$t('address.info.copy_success')"
                v-bind:copy="addressValue">
                <ui-address :address="addressValue" :hideName="true" />
            </ui-copy-button>
          </div>

          <t-skeleton-loader
              v-else
              type="paragraph"
              width="100%"
              height="24px"
              border-radius="32px"
          ></t-skeleton-loader>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppsSearchField from "~/components/apps/AppsSearchField.vue";
import IconVerified from "@primer/octicons/build/svg/verified-16.svg";
import {findOneApp} from "~/api/typesense";
import { TONSCAN_APPS_ENDPOINT } from '~/config';
import { marked } from 'marked';
import { truncateStringMiddle } from '~/utils';
import IconCopy from '@img/icons/tonscan/copy-14.svg';
import IconSuccess from '@primer/octicons/build/svg/check-16.svg';

export default {
  name: "PageAppsApp",
  components: {
    AppsSearchField,
    IconVerified,
    IconCopy,
    IconSuccess
  },
  inject: ['setIsAppPage', 'setCurrentCategory', 'getCurrentCategory'],
  props: {
    app: {
      type: String,
      required: true
    },
    categoryFrom: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: true,
      title: null,
      icon: null,
      shortDescription: null,
      shortDescriptionRu: null,
      shortDescriptionEn: null,
      description: null,
      screenshots: [],
      category: null,
      categorySlug: null,
      link: null,
      linkFull: null,
      address: null,
      addressValue: null,
      isVerified: false,
      similarApps: [],
      copyIcon: IconCopy,
      screenshotsOrientation: 'portrait'
    }
  },
  methods: {
    setDescriptionsLanguage(locale) {
      if (locale === 'ru') {
        this.description = this.descriptionRu;
        this.shortDescription = this.shortDescriptionRu;
      } else {
        this.description = this.descriptionEn;
        this.shortDescription = this.shortDescriptionEn;
      }
    },
    setLink() {
      if (!this.linkFull.includes('https://')) {
        this.linkFull = 'https://' + this.linkFull;
      }

      const linkURL = new URL(this.linkFull)

      if(linkURL.hostname === 't.me') {
        let telegramLink = `@${linkURL.pathname}`
        telegramLink = telegramLink.replace('/', '');
        this.link = telegramLink.split('/')[0]
      } else {
        if (linkURL.pathname === '/') {
          this.link = `${linkURL.hostname}`
        } else {
          this.link = `${linkURL.hostname}${linkURL.pathname}`;
        }
      }
    },
    async getApp() {
      let app = await findOneApp(this.app)
      if (!app.hits[0]) return
      app = app.hits[0].document;
      this.setApp(app);
      this.loading = false;
      this.$emit('loaded', this.categorySlug);
    },
    setApp(app) {
      this.title = app.name
      this.icon = `${TONSCAN_APPS_ENDPOINT}${app.avatar}`
      this.screenshots = app.screenshots.map((screenshot) => {
        return `${TONSCAN_APPS_ENDPOINT}${screenshot}`
      })

      if (this.screenshots) {
        const img = new Image()
        img.src = this.screenshots[0];
        img.onload = () => {
          this.screenshotsOrientation = img.width > img.height ? 'landscape' : 'portrait';
        }
      }

      this.address = app.address;
      this.linkFull = app.link
      this.setLink();

      this.descriptionRu = app.full_description_ru;
      this.shortDescriptionRu = app.short_description_ru;
      this.descriptionEn = app.full_description;
      this.shortDescriptionEn = app.short_description;

      this.setDescriptionsLanguage(this.$store.state.appLocale)

      this.addressValue = app.address;
      this.address = app.address;

      this.category = this.getCategoryBySlug(app.category_slug)

      if (app.category_slug === 'dex') {
        this.category = this.$t('apps.categories.dexs')
      } else if (app.category_slug === 'exchange') {
        this.category = this.$t('apps.categories.exchanges')
      }
      this.categorySlug = app.category_slug
    },
    getCategoryBySlug(categorySlug, nav = false) {
      for (const category of this.categories) {
        if (category.slug === categorySlug) {
          if (nav) {
            return category.navigationSlug;
          } else {
            return category.name;
          }
        }
      }
    },
    copyAddress() {
      navigator.clipboard.writeText(this.addressValue);
      this.copyIcon = IconSuccess;
      this.$bus.$emit('showToast', this.$t('Address copied'));
      setTimeout(() => {
        this.copyIcon = IconCopy;
      }, 2000);
    },
    getNavigationSlugBySlug(slug) {
      for (const category of this.categories) {
        if (category.slug === slug) {
          return category.navigationSlug
        }
      }
    }
  },
  computed: {
    categoryTitle() {
      console.log('this.categorySlug', this.categorySlug)
      if (this.categorySlug === 'dex') {
        return this.$t('apps.categories.dexs')
      } else if (this.categorySlug === 'exchange'){
        return this.$t('apps.categories.exchanges')
      }
      return this.$t(`apps.categories.${this.getNavigationSlugBySlug(this.categorySlug)}`)
    },
    descriptionMd() {
      return this.description ? marked(this.description) : null
    },
    addressToShow() {
      return this.screenSizes.isLargeScreen ? this.addressValue : truncateStringMiddle(this.address);
    },
    categories() {
      return [
        {
          name: this.$t('apps.categories.wallets'),
          slug: 'wallet',
          navigationSlug: 'wallets'
        },
        {
          name: this.$t('apps.categories.exchanges'),
          slug: 'dex',
          navigationSlug: 'exchanges'
        },
        {
          name: this.$t('apps.categories.exchanges'),
          slug: 'exchange',
          navigationSlug: 'exchanges'
        },
        {
          name: this.$t('apps.categories.marketplaces'),
          slug: 'marketplace',
          navigationSlug: 'marketplaces'
        },
        {
          name: this.$t('apps.categories.games'),
          slug: 'game',
          navigationSlug: 'games'
        },
        {
          name: this.$t('apps.categories.social'),
          slug: 'social',
          navigationSlug: 'social'
        },
        {
          name: this.$t('apps.categories.staking'),
          slug: 'staking',
          navigationSlug: 'staking'
        },
        {
          name: this.$t('apps.categories.tools'),
          slug: 'tool',
          navigationSlug: 'tools'
        },
        {
          name: this.$t('apps.categories.other'),
          slug: 'other',
          navigationSlug: 'other'
        },
      ]
    }
  },
  async beforeMount() {
    this.setIsAppPage(true);
    await this.getApp();
    document.title = `TON Explorer :: ${this.title}`;
    this.$nextTick(() => {
      this.setCurrentCategory(null)
    })
    // this.prevCategory = this.getCurrentCategory()
    // this.setCurrentCategory(this.getCategoryBySlug(this.categorySlug, true))
  },
  watch: {
    '$route': {
      async handler(to, from) {
        await this.getApp();
        document.title = `TON Explorer :: ${this.title}`;
      },
      deep: true
    },
    '$store.state.appLocale': {
      handler(locale) {
        this.setDescriptionsLanguage(locale)
        this.category = this.getCategoryBySlug(this.categorySlug)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

  $app-page-padding-x: 40px;
  $app-page-padding-y: 32px;

  .app {
    padding:
        $app-page-padding-y
        $app-page-padding-x
        0
        $app-page-padding-x;
    background: var(--card-background);
    border: 1px solid var(--card-border-color);
    border-radius: 12px;
    margin-top: 20px;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;

    &__header {
      display: flex;
    }

    &__logo {
      width: 128px;
      height: 128px;
      border-radius: 32px;
      background-size: cover;
      flex-shrink: 0;
    }

    &__heading {
      margin-left: 20px;
    }

    &__title {
      font-size: 24px;
      display: flex;
      gap: 9px;
      align-items: center;
      margin: 0;
      min-height: 24px; // For skeleton
    }

    &__short-description {
      margin-top: 12px;
      font-weight: 400;
      color: var(--app-short-description);

      // For skeleton loader
      min-width: 180px;
    }

    &__open-btn {
      margin-top: 14px;

      & a {
        display: block;
        width: fit-content;
      }
    }

    &__open-btn--wide {
      margin-top: 24px;

      & button {
        text-transform: uppercase;
      }
    }

    &__open-btn button {
      text-transform: uppercase;
      height: 40px;
      font-size: 14px;
    }

    &__open-btn a {
      text-decoration: none;
    }

    &__description {
      margin-top: 20px;
      max-width: 580px;
      font-weight: 400;
      line-height: 24px;
      color: var(--body-text-color);

      &--loading {
        width: 100%;
        min-height: 115px;
      }
    }

    &__screenshots {
      margin-right: -$app-page-padding-x;
      margin-top: 32px;
      margin-bottom: 32px;
      gap: 16px;
      overflow: auto;
      white-space: nowrap;
      display: flex;
      padding-right: 40px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__screenshot {
      border-radius: 12px;
      background-size: cover;

      &--portrait {
        width: 210px;
        height: 372px;
      }

      &--landscape {
        width: 372px;
        height: 210px;
      }

      div {
        border-radius: 12px;
      }
    }

    &__delimiter {
      background: var(--card-border-color);
      height: 1px;
      margin: 32px -40px 0 -40px;
    }

    &__info {
      margin: 32px 0;
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-right: 40px;
      gap: 50px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .item__name {
        color: var(--app-info-name);
        font-weight: 500;
      }

      .item__value {
        margin-top: 8px;
        font-weight: 400;

        .value-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
        }

        & svg {
          color: var(--icon-color);
          width: 16px;
          height: 16px;

          &:hover {
            color: var(--icon-hover-color);
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {

    $app-page-mobile-padding-x: 16px;
    $app-page-mobile-padding-y: 24px;

    .app {
      padding:
          $app-page-mobile-padding-y
          $app-page-mobile-padding-x
          0
          $app-page-mobile-padding-x;
      border: none;
      border-radius: 0;
      margin-left: -$app-page-mobile-padding-x;
      margin-top: 12px;
      width: 100%;
      border-top: 1px solid var(--card-border-color);
      border-bottom: 1px solid var(--card-border-color);

      &__delimiter {
        margin: $app-page-mobile-padding-y -16px $app-page-mobile-padding-y -16px;
      }

      &__open-btn {
        margin-top: 10px;
      }

      &__logo {
        width: 96px;
        height: 96px;
        border-radius: 24px;
      }

      &__description {
        margin-top: 24px;
      }

      &__screenshots {
        margin-top: 24px;
        margin-right: -$app-page-mobile-padding-x;
        padding-right: 16px;
      }

    }
  }
</style>
