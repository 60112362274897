<template>
  <div class="settings-modal__section">
    <div class="settings-modal__section-title">
      {{ $t('settings.localization.title') }}
    </div>

  </div>
</template>

<script>
export default {
  name: "SettingsLocalization"
}
</script>

<style scoped>

</style>
