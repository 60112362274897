<template>
  <nav :class="layoutClass">
    <router-link
        v-for="(route, index) in routes"
        :key="index"
        class="apps-navigation__item"
        :to="getToByRoute(route.routeName)"
        :class="{ 'apps-navigation__item--active': currentCategory === route.routeName }"
    >
      <div class="item__wrapper" @click="itemClick(route.routeName)">
        <component :is="route.icon"></component>
        <div class="item__name">
          {{ route.name }}
        </div>
      </div>
    </router-link>
  </nav>
</template>

<script>

// :class="{ 'apps-navigation__item--active': 'all' === route.routeName && !$route.hash && $route.name === 'apps' }"
// active-class="apps-navigation__item--active"

import IconAppsAll from "@img/icons/tonscan/apps/all.svg";
import IconAppsExchanges from "@img/icons/tonscan/apps/exchanges.svg";
import IconAppsGames from "@img/icons/tonscan/apps/games.svg";
import IconAppsMarketplaces from "@img/icons/tonscan/apps/marketplaces.svg";
import IconAppsSocial from "@img/icons/tonscan/apps/social.svg";
import IconAppsStaking from "@img/icons/tonscan/apps/staking.svg";
import IconAppsTools from "@img/icons/tonscan/apps/tools.svg";
import IconAppsWallets from "@img/icons/tonscan/apps/wallets.svg";
import IconAppsOther from "@img/icons/tonscan/apps/other.svg";

export default {
  name: "AppsNavigation",
  props: {
    type: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: false
    }
  },
  inject: ['setIsAppPage'],
  data() {
    return {
      currentCategory: null
    }
  },
  methods: {
    setCurrentCategory(resetSearch = false, category = null) {
      if (category) {
        this.currentCategory = category
        this.$emit('categoryChange', this.currentCategory);
        return
      }
      if (resetSearch) {
        this.currentCategory = this.category
      } else {
        this.currentCategory = this.$route.hash.replace('#', '');
      }

      if (!this.currentCategory) {
        this.currentCategory = 'apps';
      }
      this.$emit('categoryChange', this.currentCategory);

    },
    // changeCategory(category) {
    //   this.currentCategory = category;
    //   this.$emit('categoryChange', category);
    // },
    getToByRoute(category) {
      return { name: 'apps', hash: `#${category}` }
    },
    itemClick(category) {
      // this.setIsAppPage(true)
      console.log('itemClick')
      this.setCurrentCategory(false, category)
    }
  },
  computed: {
    layoutClass() {
      return this.type === 'left-menu' ? 'apps-navigation' : 'apps-navigation--window';
    },
    isCategoryAll() {
      return !this.$route.hash
    },
    routes() {
      return [
        {
          name: this.$t('apps.categories.all'),
          routeName: 'apps',
          icon: IconAppsAll
        },
        {
          name: this.$t('apps.categories.wallets'),
          routeName: 'wallets',
          icon: IconAppsWallets
        },
        {
          name: this.$t('apps.categories.exchanges'),
          routeName: 'exchanges',
          icon: IconAppsExchanges
        },
        {
          name: this.$t('apps.categories.marketplaces'),
          routeName: 'marketplaces',
          icon: IconAppsMarketplaces
        },
        {
          name: this.$t('apps.categories.games'),
          routeName: 'games',
          icon: IconAppsGames
        },
        {
          name: this.$t('apps.categories.staking'),
          routeName: 'staking',
          icon: IconAppsStaking
        },
        {
          name: this.$t('apps.categories.social'),
          routeName: 'social',
          icon: IconAppsSocial
        },
        {
          name: this.$t('apps.categories.tools'),
          routeName: 'tools',
          icon: IconAppsTools
        },
        {
          name: this.$t('apps.categories.other'),
          routeName: 'other',
          icon: IconAppsOther
        },
      ]
    }
  },
  beforeMount() {
    console.log('from BEFORE MOUNT')
    if (this.$route.name !== 'app') {
      this.setCurrentCategory()
    }
  },
  watch: {
    // '$route.name': {
    //   handler() {
    //     this.setCurrentCategory()
    //   }
    // },
    '$route.hash': {
      handler() {
        this.setCurrentCategory()
      }
    },
    category(newCategory) {
      if (this.category === 'apps') {
        if (window.location.hash) {
          history.replaceState(
              null,
              null,
              window.location.href.split('#')[0]
          );
        }
      }
      // this.currentCategory = this.category
      console.log('from CAT WATCH')
      if (this.$route.name === 'app') {
        console.log('route name: ', this.$route.name)
        this.$emit('categoryChange', null);
        this.currentCategory = null;
      } else {
        this.setCurrentCategory(true)
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.apps-navigation {
  display: flex;
  flex-direction: column;

  &__item {
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 48px;
    color: var(--apps-navigation-inactive-color);
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &--active {
      color: var(--apps-navigation-category-active-color);

      & svg {
        color: var(--apps-navigation-icon-active);
      }
    }

    .item__wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      .item__name {
        margin-left: 16px;
        line-height: 24px;
      }
    }
  }
}

.apps-navigation--window {
  @extend .apps-navigation;

  width: 240px;
  padding: 16px;
  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  position: absolute;

  // TODO: FIX IT TEMPORARY SOLUTION
  bottom: -515px;
  left: -133px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}


</style>
