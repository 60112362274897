<template>
  <t-row :gap="16">
    <t-col
        :cols="6"
        :tablet="6"
        :mobile="12"
        v-for="index in 20"
        :key="index"
    >
      <apps-app-card
          class="apps-list__card"
          is-loading
      >
      </apps-app-card>
    </t-col>
  </t-row>

</template>

<script>
import AppsAppCard from "~/components/apps/AppsAppCard.vue";
export default {
  name: "AppsAppCardsSkeleton",
  components: {
    AppsAppCard
  }
}
</script>

<style lang="scss" scoped>
  .apps-list__card {
    margin-bottom: 20px;
    //margin: 0 -8px;
    //border-radius: 0;
    //border-bottom: none;
  }

  @media screen and (max-width: 480px) {
    .apps-list__card {
      //margin-bottom: 20px;
      margin: 0 -16px;
      border-radius: 0;
      border-bottom: none;
    }

  }
</style>
