var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('apps-app',{attrs:{"category-from":_vm.categoryFrom,"app":_vm.app},on:{"loaded":_vm.getSimilarApps}}),_vm._v(" "),_c('div',{staticClass:"apps-similar"},[_c('div',{staticClass:"apps-similar__title"},[_vm._v(_vm._s(_vm.$t('apps.app.similar_products')))]),_vm._v(" "),_c('t-row',{attrs:{"gap":20}},_vm._l((_vm.similarApps),function(app,index){return _c('t-col',{key:index,attrs:{"cols":6,"tablet":6,"mobile":12}},[(!_vm.loading)?_c('router-link',{staticClass:"router-link__no-style",attrs:{"to":{
              name: 'app',
              params: {
                  app: app.slug
                },
              meta: {
                title: app.title
              }
            }}},[_c('apps-app-card',{staticClass:"apps-list__card",attrs:{"title":app.title,"description":app.shortDescription,"icon":app.icon,"is-ads":app.isAds,"is-trending":app.isTrending,"is-new":app.isNew,"is-verified":app.isVerified}})],1):_vm._e()],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }