<template>
  <div class="filters-wrapper">
    <div class="apps-filters">
      <div class="apps-filters__title">
        Filters
      </div>
      <div class="apps-filters__settings">
        <div class="settings__sort">
          <label
              class="container"
              v-for="option in filterOptions"
              :key="option.value"
          >
            <span>{{ option.name }}</span>
            <input
                type="radio"
                :value="option.value"
                :checked="selectedFilter === option.value"
                name="filterGroup"
                @click="select(option.value)"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="settings__verified">
          <span>Verified only</span>
          <t-switch v-model="isVerified"></t-switch>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      selectedFilter: 'popular',
      filterOptions: [
        {
          name: 'Popular',
          value: 'popular'
        }, {
          name:'Newest',
          value: 'newest'
        }
      ],
      isVerified: false
    };
  },
  methods: {
    select(optionValue) {
      this.selectedFilter = optionValue;
      this.updateFilters();
    },
    updateFilters() {
      const filters = {
        filter: this.selectedFilter,
        verified: this.isVerified
      }
      this.$store.dispatch('updateAppsPageFilters', filters)
    }
  },
  watch: {
    isVerified(newVal) {
      this.updateFilters()
    }
  }
};
</script>

<style lang="scss" scoped>

  .filters-wrapper {
    top: 200%;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 16px;
    width: 260px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .apps-filters {
    font-size: 14px;
    font-weight: 500;

    &__title {
      text-transform: uppercase;
      color: #929497;
    }

    &__settings {
      color: #24292F;

    }

    .settings__verified {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      margin-top: 20px;
    }

    .settings__sort {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
  .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border-color: var(--app-info-value);
  }

  .checkmark {
    border: 2px solid var(--app-info-value);
  }

  //.container:hover input ~ .checkmark {
  //  background-color: var(--app-info-value);
  //}

  .container input:checked ~ .checkmark {
    background-color: #FFFFFF;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--app-info-value);
  }

  input[type='radio'] {
    appearance: auto!important;
  }


</style>
