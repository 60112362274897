<template>
  <div class="app-card">
    <div class="app-card__icon-wrapper" v-if="!isLoading">
      <img v-if="!isImageError" @error="setErrorImage()" class="app-card__icon" :src="icon">
      <div class="app-card__icon" v-else>
        <icon-broken-image class="broken-icon"></icon-broken-image>
      </div>
    </div>
    <div v-else class="app-card__icon-wrapper">
      <div class="app-card__icon">
        <t-skeleton-loader
            type="rectangle"
            height="100%"
            width="100%"
            border-radius="inherit"
        >
        </t-skeleton-loader>
      </div>
    </div>

    <div class="app-card__content">
      <div class="app-card__heading">
        <div class="app-card__title">
          <span v-if="!isLoading">{{ title }}</span>
          <t-skeleton-loader
              v-else
              type="paragraph"
              height="24px"
              width="100px"
              border-radius="24px"
          >
          </t-skeleton-loader>
        </div>
        <t-chip
            class="app-card__chip"
            :class="`app-card__badge--${badge}`"
            v-if="badge && badge !== 'verified'"
        >
          {{ badge }}
        </t-chip>
        <icon-verified class="app-card__icon-verified" v-else-if="badge === 'verified' && !isLoading"></icon-verified>
      </div>
      <div class="app-card__description">
        <span v-if="!isLoading">{{ description }}</span>
        <t-skeleton-loader
            v-else
            type="paragraph"
            height="24px"
            width="100%"
            border-radius="24px"
        >
        </t-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>

import IconVerified from "@img/icons/tonscan/apps/verified.svg";
import IconBrokenImage from "@img/icons/tonscan/image-broken.svg";

export default {
  name: "AppsAppCard",
  components: {
    IconVerified,
    IconBrokenImage
  },
  props: {
    icon: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    isAds: {
      type: Boolean,
      required: false,
      default: false
    },
    isTrending: {
      type: Boolean,
      required: false,
      default: false
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    },
    isVerified: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isImageError: false,
      // Приоритет бейджиков, чтобы не отображать одновременно и галочку и HOT и NEW в общем списке приложений
      // — это выше описал (isAds, потом isTrending, isNew, isVerified — что выше, то в приоритете отображается, когда стоят несколько).
      badgePriority: ['isAds', 'isTrending', 'isNew', 'isVerified'],
      badge: null,
    }
  },
  methods: {
    setErrorImage() {
      this.isImageError = true
    },
    setPriorityBadge() {
      for (const badge of this.badgePriority) {
        if (this[badge]) {
          // Key is a property from server
          // Value is the prefix of the class
          const classes = {
            'isAds': 'ad',
            'isTrending': 'hot',
            'isNew': 'new',
            'isVerified': 'verified'
          }

          this.badge = classes[badge];
          return
        }
      }

    },
  },
  computed: {
    // badgeClass() {
    //   console.log('compbadge', this.badge);
    //
    //   if (this.badge !== null) {
    //     return `app-card__badge--${this.badge.replace('is', '').toLowerCase()}`
    //   }
    // },
  },
  beforeMount() {
    if (!this.icon) {
      this.setErrorImage();
    }
    this.setPriorityBadge();
  }
}
</script>

<style lang="scss" scoped>

  $app-card-min-height: 85px;
  $app-card-border-radius: 12px;
  $app-card-width: $app-card-min-height;
  $app-card-icon-width: $app-card-min-height;
  $app-card-icon-height: $app-card-min-height;

  .app-card {
    display: flex;
    border-radius: $app-card-border-radius;
    border: 1px solid var(--card-border-color);
    background: var(--card-background);
    cursor: pointer;
    min-height: $app-card-min-height;
    z-index: 1;

    &__title {
      color: var(--body-text-color);
    }

    &__icon {
      border-radius: $app-card-border-radius - 1px 0 0 $app-card-border-radius - 1px;
      width: $app-card-icon-width;
      height: $app-card-icon-height;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--app-icon-background);

      & svg {
        color: #959a9e;
        width: 75%;
        height: 75%;
      }
    }

    &__icon-wrapper {
      width: $app-card-width;
    }

    &__heading {
      display: flex;
      align-items: center;
    }

    &__content {
      //width: calc(100% - 100px);
      padding: 10px 16px 10px 16px;
    }

    &__description {
      margin-top: 6px;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      line-height: 20px;

      & span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--app-card-description);
      }
    }

    &__chip {
      margin-left: 5px;
      margin-top: -1px;
    }

    &__icon-verified {
      margin-left: 4px;
      color: var(--app-icon-verified-color);
      transform: translateY(-1px);
    }

    &__badge {
      text-transform: uppercase;

      &--hot {
        background: rgba(223, 137, 80, 0.24);
        color: rgba(223, 137, 80);
      }

      &--new {
        background: var(--app-card-badge-new-background);
        color: #54B893;
      }

      &--ad {
        //background: #D5F1EC;
        //color: #429174;
      }
    }
  }

  @media screen and (max-width: 480px) {

    $app-card-border-radius-small-screen: 16px;

    .app-card {
      min-height: 96px;

      &__icon {
        width: 64px;
        height: 64px;
        display: flex;
        border-radius: $app-card-border-radius-small-screen;
      }

      &__icon-wrapper {
        padding: 16px 0 16px 16px;
        width: auto;
      }

      &__content {
        padding: 16px;
      }
    }

  }
</style>
