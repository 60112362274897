<template>
  <div>
    <apps-app @loaded="getSimilarApps" :category-from="categoryFrom" :app="app"></apps-app>
    <div class="apps-similar">
      <div class="apps-similar__title">{{ $t('apps.app.similar_products') }}</div>
      <t-row :gap="20">
        <t-col
            v-for="(app, index) in similarApps"
            :key="index"
            :cols="6"
            :tablet="6"
            :mobile="12"
        >
          <router-link
              v-if="!loading"
              class="router-link__no-style"
              :to="{
                name: 'app',
                params: {
                    app: app.slug
                  },
                meta: {
                  title: app.title
                }
              }"
          >
            <apps-app-card
                class="apps-list__card"
                :title="app.title"
                :description="app.shortDescription"
                :icon="app.icon"
                :is-ads="app.isAds"
                :is-trending="app.isTrending"
                :is-new="app.isNew"
                :is-verified="app.isVerified"
            >
            </apps-app-card>
          </router-link>
        </t-col>
      </t-row>
    </div>
  </div>
</template>

<script>
import AppsApp from "~/components/apps/AppsApp.vue";
import AppsAppCard from "~/components/apps/AppsAppCard.vue";
import {searchApps} from "~/api/typesense";
import {TONSCAN_APPS_ENDPOINT} from "~/config";
export default {
  name: "AppsAppWrapper",
  components: {
    AppsAppCard,
    AppsApp
  },
  props: {
    app: {
      type: String,
      required: true
    },
    categoryFrom: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      similarApps: [],
      loading: true,
      category: null
    }
  },
  methods: {
    async getSimilarApps(category) {
      this.category = category;
      const similarApps = await searchApps({
        q: `${category} -${this.app}`,
        query_by: 'category_slug,name',
        page: 1,
        per_page: 2
      })
      this.similarApps = similarApps.hits.map((item) => {
        item = item.document

        const appObj = {
          title: item.name,
          icon: `${TONSCAN_APPS_ENDPOINT}${item.avatar}`,
        }

        if (item.is_new) {
          appObj.chip = 'new'
        }

        if (item.is_trending) {
          appObj.chip = 'hot'
        }

        // 'isAds', 'isTrending', 'isNew', 'isVerified'
        appObj.slug = item.slug
        appObj.isAds = item.is_ads
        appObj.isTrending = item.is_trending
        appObj.isNew = item.is_new
        appObj.isVerified = item.is_verified

        // console.log(appObj.avatar)
        // appObj.icon = require('@img/test-square.png')

        appObj.shortDescriptionEn = item.short_description
        appObj.shortDescriptionRu = item.short_description_ru


        if (this.$store.state.appLocale === 'ru') {
          appObj.shortDescription = appObj.shortDescriptionRu;
        } else {
          appObj.shortDescription = appObj.shortDescriptionEn;
        }

        return appObj;

      })
      this.loading = false;
    },
  },
  watch: {
    '$store.state.appLocale': {
      handler(locale) {
        this.getSimilarApps(this.category)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .apps-similar {
    margin-top: 40px;

    &__title {
      text-transform: uppercase;
      font-size: 14px;
      color: #858585;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    .apps-similar {
      .t-row {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
</style>
