<template>
  <div class="t-selector" :style="selectorStyles">
    <div
        class="t-selector__item"
        v-for="(item, index) in items"
        :class="{ 't-selector__item--selected': value === item.value }"
        :key="index"
        @click="selectItem(item.value)"
        ref="selectorItems"
    >
        <component class="t-selector__item-icon" v-if="item.icon" :is="item.icon"></component>
        <span class="t-selector__item-name">{{ item.name }}</span>
<!--        <t-delimiter vertical></t-delimiter>-->
    </div>
    <div class="t-selector__border" :style="borderStyle"></div>
  </div>
</template>

<script>

export default {
  name: "TSelector",
  props: {
    type: {
      type: String,
      default: 'small',
      validator(value) {
        return value === 'small' || value === 'big'
      }
    },
    height: {
      type: String,
      required: false,
      default: '32px'
    },
    // Sets width to 100%
    block: {
      type: Boolean,
      required: false,
      default: false
    },
    delimiter: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Array of objects:
     * {
     *   name: 'Name',
     *   value: 'name'
     *   icon: IconComponent
     * }
     *
     */
    items: {
      type: Array,
      required: true
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      elementsWidths: [0],
      currentElementWidth: 0,
      previousSelectedItemIndex: null
      // selectedItem: this.items[0].value
    }
  },
  methods: {
    selectItem(value) {
      // console.log(value)
      // this.selectedItem = value
      // this.$emit('input', value);

      for (let i = 0; i < this.items.length; i++) {
        if (this.value === this.items[i].value) {
          this.previousSelectedItemIndex = i;
          break;
        }
      }

      console.log(this.previousSelectedItemIndex)

      this.$emit('select', value);
      this.$emit('input', value);
    },
    setElementsWidths() {
      this.elementsWidths = [];
      if (this.$refs.selectorItems && this.$refs.selectorItems.length > 0) {
        this.$refs.selectorItems.forEach(element => {
          this.elementsWidths.push(element.offsetWidth)
        });
      }
    }
  },
  computed: {
    selectorStyles() {
      return {
        height: this.height,
        width: this.block ? 'auto' : 'fit-content'
      }
    },
    borderStyle() {
      this.setElementsWidths()

      const values = this.items.map((item) => {
        return item.value;
      })
      const selectedItemIndex = values.indexOf(this.value);

      let width = this.elementsWidths[selectedItemIndex] - 2; // -border width

      let translateXPx = 0;
      if (selectedItemIndex !== 0) {
        for (let i = 0; i < selectedItemIndex; i++) {
          translateXPx += this.elementsWidths[i]
        }
      }

      return { 'transform': `translateX(${translateXPx}px)`, 'width': `${width}px` };
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setElementsWidths()
    });
  },
  watch: {
    'screenSizes': {
      handler() {
        this.setElementsWidths()
      },
      deep: true
    },
    // selectedElement
  },
  beforeMount() {
    // this.selectItem(this.selectedItem);
    this.previousSelectedItemIndex = this.items.find((item) => {
      return item.name === this.selectItem
    });

    console.log(this.previousSelectedItemIndex)
  }
}
</script>

<style lang="scss" scoped>

  .t-selector {
    //background: var(--selector-background);
    display: flex;
    //border-radius: 8px;
    line-height: 24px;
    width: fit-content;
    align-items: center;
    max-height: 38px;
    position: relative;

    padding: 2px 0;
    background: var(--card-background);
    border-radius: 12px;

    &__item {
      height: 100%;
      border-radius: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid transparent;
      flex: 1;
      //width: 115px;
      padding: 16px 20px;
      color: var(--selected-big-text);
      cursor: pointer;
      font-weight: 500;

      &-icon {
        margin-right: 8px;
      }

      &--selected {
        //border: var(--selector-big-selected-border);
        color: var(--selector-big-selected-text);
        background: var(--selector-big-selected-background);
      }
    }

    &__border {
      position: absolute;
      bottom: 0;  // Adjust depending on where you want the border to appear
      left: 0;
      //width: calc(50%);
      height: calc(100% - 4px);  // Thickness of the sliding border
      border: 2px solid var(--selector-big-selected-border);  // Color of the sliding border
      transition: all 0.3s ease-in-out;
      border-radius: 12px;
      //z-index: -1;
    }
  }

  @media screen and (max-width: 480px) {

    .t-selector {
      border: 1px solid var(--selector-big-mobile-border-color);
      //border: 1px solid red;

      &__item {
        padding: 8px 16px;
      }

      &__border {
        height: calc(100% - 2px);
        bottom: -1px;
      }
    }
  }

</style>
