<template>
  <section class="apps">
    <t-container>
      <t-row>
        <t-col :cols="3" mobile="hidden" tablet="hidden">
          <apps-navigation
              :category="currentCategory"
              @categoryChange="setCurrentCategory"
              type="left-menu"
          ></apps-navigation>
        </t-col>
        <t-col :cols="9" :tablet="12" :mobile="12">
          <div class="apps__content">
            <apps-banner v-if="showBanner && !isAppPage" :is-loading="isPageLoading"></apps-banner>
            <div class="apps__search-filter-wrapper">
              <apps-search-field
                  :search-value="searchValue"
                  @search-input-changed="setSearchValue"
                  class="apps__search"
              ></apps-search-field>
              <t-selector
                  :value="currentFilter"
                  height="inherit"
                  :items="appsFilters"
                  v-if="screenSizes.isLargeScreen && !isAppPage"
                  @select="updateFilter"
              ></t-selector>
            </div>
            <router-view v-if="$route.params.app && searchValue === ''"></router-view>
            <apps-list
                :current-filter="currentFilter"
                v-else
                :search-value.sync="searchValue"
                @clear="clearSearchValue"
                @filterUpdate="updateFilter"
            ></apps-list>
          </div>
        </t-col>
      </t-row>
    </t-container>
  </section>
</template>

<script>
import AppsSearchField from "./AppsSearchField.vue";
import AppsNavigation from "~/components/apps/AppsNavigation.vue";
import AppsBanner from "~/components/apps/AppsBanner.vue";
import AppsList from "~/components/apps/AppsList.vue";

export default {
  name: "PageApps",
  components: {
    AppsList,
    AppsNavigation,
    AppsSearchField,
    AppsBanner
  },
  provide() {
    return {
      setIsAppPage: (value) => {
        this.isAppPage = value
      },
      setIsAppPageLoading: (value) => {
        this.isPageLoading = value
      },
      getCurrentCategory: () => this.currentCategory,
      setCurrentCategory: (category) => this.setCurrentCategory(category)
    }
  },
  data() {
    return {
      desktopBannerURL: require('@img/banner-new.png'),
      mobileBannerURL: require('@img/banner-mobile.png'),
      currentCategory: 'apps',
      currentFilter: 'popular',
      // showBannerOn: ['all'],
      isAppPage: false,
      isPageLoading: true,
      searchValue: '',
      showBanner: true
    }
  },
  computed: {
    // showBanner() {
    //   return true;
      // Uncomment if you need to show banner on specific page
      // return this.showBannerOn.includes(this.$route.name)
    // },
    // isAppPage() {
    //   const categoryRouteNames = this.routes.map((route) => route.routeName)
    //   return !categoryRouteNames.includes(this.$route.name)
    // },
    bannerURL() {
      return this.isSmallScreen ? this.mobileBannerURL : this.desktopBannerURL
    },
    appsFilters() {
      return [
        {
          name: this.$t('apps.filters.top'),
          value: 'popular'
        },
        {
          name: this.$t('apps.filters.newest'),
          value: 'newest'
        },
        {
          name: 'Teeeeeest',
          value: 'test'
        }
      ]
    }
  },
  methods: {
    clearSearchValue() {
      this.searchValue = ''
    },
    updateFilter(filter) {
      this.currentFilter = filter;
    },
    setCurrentCategory(category) {
      if (category === this.currentCategory) {
        return
      }
      this.currentCategory = category
    },
    setSearchValue(value) {
      this.searchValue = value;
      if (this.isAppPage) {
        // this.showBanner = false;
        this.$router.push({name: 'apps'})
        // this.$nextTick(() => {
        //   this.isAppPage = true;
        // });
      }
      this.setCurrentCategory('apps');
    }
  },
  watch: {
    // currentFilter() {
    //     console.log('NEF CF = ', this.currentFilter)
    // },
    '$route': {
      handler(to, from) {
        if (to.name === 'app') {
          this.searchValue = '';
          this.isAppPage = true;
        }
        if (from.name === 'app' && to.name === 'apps') {
          this.setCurrentCategory(this.categoryFrom);
        }
        if (from.hash !== to.hash) {
          this.setCurrentCategory(to.hash.replace('#', ''))
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>

  .apps {
    font-weight: 500;
    margin-top: 2px;

    &__content {
      padding-left: 20px;
    }

    &__search-filter-wrapper {
      display: flex;
      gap: 15px;
    }
  }

  @media screen and (max-width: 1240px) {
    .apps {
      &__content {
        padding: 0 8px;
        border-radius: 0;
      }
    }
  }

</style>
