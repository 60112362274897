
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 14 14"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"fill":"none","fill-rule":"evenodd","stroke":"currentColor","stroke-width":"1.3","stroke-linejoin":"round"}},[_c('path',{attrs:{"d":"M6.36 3.35h3.29a2 2 0 012 2v5.3a2 2 0 01-2 2H6.36a2 2 0 01-2-2v-5.3a2 2 0 012-2z"}}),_c('path',{attrs:{"d":"M2 10.65V3.3A2.3 2.3 0 014.3 1h5.4"}})])])
          )
        }
      }
    