var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"apps-list"},[(!_vm.screenSizes.isLargeScreen)?_c('div',{staticClass:"apps-list__info"},[_c('div',{staticClass:"apps-list__nav",class:{ 'apps-list__nav--open': _vm.mobileNavigationOpen }},[_c('div',{staticClass:"nav-btn",class:{ 'nav-btn--open': _vm.firstMobileCategoryOpen},on:{"click":function($event){return _vm.toggleMobileNavigation()}}},[_c(_vm.firstMobileCategory.icon,{tag:"component",staticClass:"nav-btn__icon"}),_vm._v(" "),_c('div',{staticClass:"nav-btn__text"},[_vm._v("\n          "+_vm._s(_vm.firstMobileCategory.name)+"\n        ")])],1),_vm._v(" "),_vm._l((_vm.routes),function(category){return _c('router-link',{key:category.routeName,staticClass:"nav-btn__link router-link__no-style",style:({
              'visibility': _vm.mobileNavigationOpen ? 'visible' : 'hidden',
            }),attrs:{"to":{ name: 'apps', hash: `#${category.routeName}` }}},[_c('div',{staticClass:"nav-btn",class:{ 'nav-btn--open': _vm.currentCategory === category.routeName },on:{"click":function($event){return _vm.setCategoryFromMobile(category)}}},[_c(category.icon,{tag:"component",staticClass:"nav-btn__icon"}),_vm._v(" "),_c('div',{staticClass:"nav-btn__text"},[_vm._v("\n              "+_vm._s(category.name)+"\n            ")])],1)])})],2),_vm._v(" "),(!_vm.mobileNavigationOpen)?_c('div',{staticClass:"apps-list__filter-selector"},[_c('t-selector',{attrs:{"height":"38px","items":_vm.appsFilters},on:{"select":_vm.emitFilterUpdate},model:{value:(_vm.currentFilter),callback:function ($$v) {_vm.currentFilter=$$v},expression:"currentFilter"}})],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('t-row',{attrs:{"gap":_vm.cardsGap}},_vm._l((_vm.apps),function(app){return _c('t-col',{key:app.id,attrs:{"cols":6,"tablet":_vm.tabletCols,"mobile":12}},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"router-link__no-style apps-list__card-link",attrs:{"to":{
            name: 'app',
            params: {
              app: app.slug,
              categoryFrom: _vm.$route.hash.replace('#', '') || 'apps'
            },
          }}},[_c('apps-app-card',{staticClass:"apps-list__card",attrs:{"title":app.title,"description":app.shortDescription,"icon":app.icon,"is-ads":app.isAds,"is-trending":app.isTrending,"is-new":app.isNew,"is-verified":app.isVerified},nativeOn:{"click":function($event){return _vm.appCardClick(app.categorySlug)}}})],1)],1)}),1),_vm._v(" "),(_vm.loading)?_c('apps-app-cards-skeleton'):_vm._e(),_vm._v(" "),(_vm.loadingMoreApps)?_c('apps-app-cards-skeleton'):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.apps.length === 0)?_c('apps-search-not-found',{staticClass:"app-list__not-found"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }