<template>
  <t-modal v-model="isShow" @close="$emit('input', false)">
    <div class="settings-modal">
      <div class="settings-modal__header">
        <div class="settings-modal__header-title">{{ $t('settings.title') }}</div>
        <div class="settings-modal__close">
          <icon-close></icon-close>
        </div>
      </div>
      <t-delimiter></t-delimiter>
      <div class="settings-modal__content" @click="$emit('close')">
        <settings-appearance></settings-appearance>
        <settings-localization></settings-localization>
      </div>
    </div>
  </t-modal>
</template>

<script>
import IconClose from "@primer/octicons/build/svg/x-24.svg";
import TModal from "~/framework/TModal.vue";
import SettingsAppearance from "~/components/settings-v2/SettingsAppearance.vue";
import SettingsLocalization from "~/components/settings-v2/SettingsLocalization.vue";

export default {
  name: "SettingsModal",
  components: {
    SettingsLocalization,
    SettingsAppearance,
    TModal,
    IconClose
  },
  props: {
    value: {
      type: Boolean
    },
  },
  data() {
    return {
      isShow: true,
    }
  },
  watch: {
    value(newValue) {
      this.isShow = newValue
    },
  }
}
</script>

<style lang="scss" scoped>

  $header-padding: 20px;

  ::v-deep .settings-modal {
    color: var(--body-text-color);
    background: var(--card-background);
    border-radius: 16px;
    position: relative;
    width: 480px;

    &__close {
      cursor: pointer;
      position: absolute;
      right: $header-padding;
      top: $header-padding;
      max-height: 24px;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    &__header {
      padding: $header-padding;
      display: flex;
      justify-content: center;
      align-items: center;

      &-title {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    &__content {
      padding: $header-padding * 2;
      display: flex;
      gap: 32px;
      flex-direction: column;
    }

    &__section-title {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      color: var(--body-muted-text-color);
      margin-bottom: 16px;
    }
  }

</style>
