<template>
  <div class="apps-banner" :class="{ 'apps-banner--loading': isLoading }">
    <div class="banner__overlay" v-if="!isLoading">
      <h2 class="banner__heading" v-html="$t('apps.banner.title')"></h2>
      <div class="banner__actions">
        <router-link :to="{ name: 'submit-app' } " class="action__submit">
          <icon-banner-plus></icon-banner-plus>
          <div>{{ $t('apps.banner.submit_app') }}</div>
        </router-link>
        <router-link :to="{ name: 'learn-more' }">{{ $t('apps.banner.learn_more') }}</router-link>
      </div>
    </div>
    <template v-else>
      <t-skeleton-loader
          type="rectangle"
          width="100%"
          height="100%"
          border-radius="inherit"
      ></t-skeleton-loader>
    </template>
  </div>
</template>

<script>
import IconBannerPlus from "@img/icons/tonscan/apps/banner-plus.svg";

export default {
  name: "AppsBanner",
  components: {
    IconBannerPlus
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

  .apps-banner {
    position: relative;
    height: 260px;
    margin-bottom: 20px;
    background-image: url("~/src/img/banner-new.png");
    background-position: center;
    background-size: cover;
    border-radius: 12px;

    &--loading {
      background: none;
    }

    .banner {
      &__heading {
        font-size: 32px;
        text-align: center;
        margin-bottom: 24px;
      }

      &__image {
        width: 100%;
        max-width: 880px;
        border-radius: 12px;
      }

      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
      }

      &__actions {
        display: flex;
        gap: 32px;

        & > a {
          text-decoration: none;
          color: #FFF;
        }

      .action__submit {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .apps-banner {
      height: 240px;
      margin: -12px -16px 12px -16px;
      //background-image: url("~/src/img/banner-mobile.png");
      border-radius: 0;

      .banner {

        &__heading {
          //text-align: left;
          font-size: 24px;
        }

        &__image {
          border-radius: 0;
          height: 240px;
        }

        &__overlay {
          display: flex;
          //align-items: start;
          //margin-left: 28px;
        }
    }
  }
}

@media screen and (max-width: 1240px) and (min-width: 481px) {
  .apps-banner {
      margin: -16px -16px 20px -16px;
      border-radius: 0;
  }
}

</style>
